<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：每天价格-限价列表</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8" v-show="true">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="searchProfits" size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="danger" round
                                           :disabled="this.isBlankVue(this.idList)"
                                           @click="deleteEveryDayPriceList" size="mini">删除
                                </el-button>
                                <vxe-toolbar class="pull-right" style=" height: 42px; " import custom export
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <!--:merge-cells="mergeCells"-->
                        <!--:span-method="mergeRowMethod"-->
                        <!--:footer-method="footerMethod"
                        show-footer-->
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGrid"
                                ref="xGrid"
                                align="center"
                                rowId="id"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :print-config="{}"
                                :export-config=tableExport
                                :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                }"
                                :custom-config="{storage:true}"
                                @cell-click="cellCheckboxRowEventGrid"
                                @checkbox-range-change="setIdList"
                                @checkbox-all="setIdList"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :merge-cells="mergeCells"
                                :mouse-config="{selected: false}"
                                :checkbox-config="{range:true}"
                                :row-class-name="tableRowClassName"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <vxe-modal width="1220px" :title="!isShowDialog ? '失败' : '导入数据'" v-model="dialogImprotVisible"
                   :esc-closable="true" :lock-view="false" :mask="false" resize>
            <el-tag type="danger" style="margin-bottom: 5px">只会更新：“最低限价”、“成本价格”</el-tag>
            <vxe-grid
                    border
                    resizable
                    show-overflow
                    height="400"
                    ref="xGridImport"
                    :columns="tableImport"
                    :data="tableDataImport"
                    :cell-class-name="cellClassName"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div align="center" style="margin-top: 10px">
                <template v-if="isShowDialog">
                    <el-button @click="dialogImprotVisible = false">取 消</el-button>
                </template>
                <template v-else>
                    <el-button @click="dialogImprotVisible = false">导入失败</el-button>
                </template>
                <el-button type="primary" @click="saveImport" v-show="isShowDialog">确 定</el-button>
            </div>
        </vxe-modal>
    </div>
</template>

<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            // 通用行合并函数（将相同多列数据合并为一行）
            return {
                dialogImprotVisible:false,
                tableDataImport: [],
                tableImport: [
                    {
                        field:'id', width: 50, title: 'id'
                    },
                    {
                        field:'category', width: 50, title: '类别'
                    },
                    {
                        field:'specification', width: 150, title: '规格名称'
                    },{
                        field:'full_thick', width: 100, title: '足厚'
                    },{
                        field:'polish_roll', width: 100, title: '打磨驳口'
                    },{
                        field:'company_name', width: 150, title: '供应商/批号'
                    },
                    {
                        field:'limited_price', width: 100, title: '最低限价'
                    },
                    {
                        field:'cost_price', width: 100, title: '成本价格'
                    },
                    {
                        field:'create_date', width: 170, title: '限价日期'
                    },
                    {
                        field:'error', title: '系统提示', width: 200, slots: {
                            default: ({row}) => {
                                return [
                                    <el-link type="danger">{row.error}</el-link>
                            ]
                            },
                        }
                    }
                ],
                idList: '',
                isShowDialog: '',
                /* {row: 1, col: 9, rowspan: 2, colspan: 1},*/
                mergeCells: [],
                formData: {//搜索筛选
                    showCancel:false,
                    showAllBalance:false,
                    name: null,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                //visible:false,
                tableColumn: [
                    {type: 'checkbox',  title: '', width: 20},
                    {field:'id', title: 'ID', width: 70, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'create_date', width: 190, title: '限价日期',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'specification', width: 150, title: '实际规格',  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'category', width: 50, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'full_thick', width: 60, title: '足厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'polish_roll', width: 100, title: '打磨驳口', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'company_name', width: 150, title: '供应商/批号',
                        titleHelp: {message: '盘点单据则显示批号'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'limited_price', width: 100, title: '最低限价',formatter: ({cellValue}) => {
                            return cellValue ? cellValue : 0
                        },  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'cost_price', width: 100, title: '成本价格',formatter: ({cellValue}) => {
                            return cellValue ? cellValue : 0
                        },  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'a_side', width: 65, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'b_side', width: 65, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'thickness', width: 65, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'length_extent', width: 65, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                    {
                        field:'single_weight', width: 100, title: '支重(理论)',visible:false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    }, {
                        field:'category_class', width: 240, title: '所属分类', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/everyDayPriceList/searchHeard', this.pickerDate, this.formData, this.checkedGridClassIds,this.searchEveryList)
                        }
                    },
                ],
                tableDataMain: [],
                filterData: {

               },
                sortData: {
                    "create_date":"asc",
                },
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                tableExport: {
                    // 默认选中类型
                    columns:[
                        {field:'id'},
                        {field:'create_date'},
                        {field:'specification'},
                        {field:'category'},
                        {field:'full_thick'},
                        {field:'polish_roll'},
                        {field:'company_name'},
                        {field:'limited_price'},
                        {field:'cost_price'},
                        ],
                    filename: '每天价格-限价列表',
                    isMerge:true,
                    isFooter:true,
                    type: 'xlsx',
                    isColgroup: true,
                    // 自定义类型
                    types: ['xlsx', 'csv', 'html', 'xml', 'txt']
               },
           }
       },
        methods: {
            cellClassName({column}) {
                if(column.property == 'limited_price' || column.property == 'cost_price'){
                    return 'red-row';
                } else {
                    return '';
                }
            },
            mergeRowMethod({row, _rowIndex, column, visibleData}){
                /*{row: 1, col: 9, rowspan: 2, colspan: 1}*/
                const fields = ['sum_balance']
                const cellValue = row['customer']
                //console.log('column.property',column.property)
                if (cellValue && fields.includes(column.property)) {
                    const prevRow = visibleData[_rowIndex - 1]
                    console.log('prevRow',prevRow)
                    let nextRow = visibleData[_rowIndex + 1]
                    if (prevRow && prevRow['customer'] === cellValue) {
                        return {rowspan: 0, colspan: 0}
                   } else {
                        let countRowspan = 1
                        while (nextRow && nextRow['customer'] === cellValue) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                       }
                        if (countRowspan > 1) {
                            return {rowspan: countRowspan, colspan: 1}
                       }
                   }
               }
           },
            openReceipt(row){
                this.$router.push({path: '/receipt', query: {cash_type_id: row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}})
           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return this.$XEUtils.commafy(Number(obj), {digits: 2})
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.NumberAdd(count, item[field]);
               })
                return this.getNumberDefaultZero(count)
           },

            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 1) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'profit_total' || column.property === 'single_count'|| column.property === 'weighing_heavy_actual') {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
           searchProfits() {
                this.tablePage.currentPage = 1;
                this.getProfits();
           },
            getProfits() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/everyDayPriceList/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.mergeCells = response.data.mergeCells
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
           handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getProfits();
           },
           tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            cellCheckboxRowEventGrid({row, rowIndex, column}) {
                //console.log(row, rowIndex,column)
                //console.log(column)
                if (column.type != 'checkbox') {
                    if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    } else {
                        this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                    }

                }
                this.$refs.xGrid.clearSelected();
                this.setIdList()
            },
            setIdList() {
                let selectRecords = this.$refs.xGrid.getCheckboxRecords();//vue子组件调用父组件ref
                this.idList = ''
                selectRecords.forEach(item => {
                    this.idList = item.id + ',' + this.idList;
                });
            },
            deleteEveryDayPriceList() {
                //console.log(this.$refs.xGrid.getCheckboxRecords());
                //console.log('selectRecords', selectRecords);
                let msg = '您确定要删除：' + this.idList + 'ID?删除后不能恢复。';
                //console.log('this.idList.length: ' + this.idList.length)
                //console.log('this.idList.substr(0,20): ' + this.idList.substr(0,20))
                if (this.idList.length > 20) {
                    msg = '您确定要删除：' + this.idList.substr(0, 20) + '...等等单号?作废后不能修改，不能反作废。';
                }
                this.$XModal.confirm(msg).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据处理中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/everyDayPriceList/deleteEveryDayPriceList',
                            data: {
                                id: this.idList
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            loading.close();
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                                });
                                this.searchProfits();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            loading.close();
                            console.log(error)
                        });
                    }
                })
            },
            importMethod({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method: 'post',
                    url: '/admin/everyDayPriceList/importExcel',
                    data: formBody,
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableDataImport = response.data.list
                        this.isShowDialog = response.data.isOkSave
                        this.uuid = response.data.uuid
                        this.dialogImprotVisible = true;
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                    });
                    console.log(error)
                });
            },
            saveImport() {
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'post',
                    url: '/admin/everyDayPriceList/saveImportExcel',
                    params: {
                        uuid: this.uuid
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                        });
                        this.dialogImprotVisible = false
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                    this.searchProfits();
                }).catch((error) => {
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                    });
                    console.log(error)
                });
            },
       },computed: {

       },
        created() {

            this.getProfits();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }
    /deep/ .vxe-cell-help-icon.vxe-icon--question{
        display: -webkit-box !important;
        position: absolute !important;
    }
</style>
